<template lang="pug">
div
  div.notice-index(
    v-for="(item,index) in listdata",
    :key="index"
  )
    div.item-name
      div
        span.title 举报时间 ：
        span.content {{item.create_time}}
      div
        van-button(
          v-if="item.is_cancel===1"
          plain
          type="danger"
          size="mini"
          @click="revoke(item.id)"
        ) 撤销
    div.item
      span.title 姓名 ：
      span.content {{item.complainant_name}}
    div.item
      span.title 性别 ：
      span.content {{item.complainant_sex}}
    div.item
      span.title 手机号 ：
      span.content {{item.complaint_phone}}
    div.item
      span.title 投诉类别 ：
      span.content {{item.complaint_kind_one_text}} {{item.complaint_kind_two_text}}
    //- div.item
    //-   span.title 举报问题所在地 ：
    //-   span.content {{ item.county_name }}
    div.item
      span.title 位置信息 ：
      span.content {{item.location}}
    div.item
      span.title 举报情况 ：
      span.content {{item.report_situation}}
    div.item
      span.title 办理诉求 ：
      span.content {{item.appeal}}
    div.images-title(v-if="item.attach.length") 附件：
    div.images-box
      van-image.image-item(
        v-for="(itemson,index) in item.attach",
        :key="index",
        @click="clickImage(item.attach,index)",
        width="50",
        height="50",
        :src="itemson.url"
      )
    //- div.item(
    //-   style="margin-top:2px;",
    //-   v-if="item.content"
    //- )
    //-   span.title 答复内容 ：
    //-   span.content {{item.content}}
    div.item(
      style="margin-top:2px;",
      v-if="item.reply_content"
    )
      span.title 处理结果 ：
      span.content {{item.reply_content}}
    div.status(v-if="item.cancel_flag===0") 已撤销
    div.progress(v-if="item.cancel_flag!==0") {{item.state_text}}
</template>
<script>
import { ImagePreview, Dialog, Toast } from 'vant'
import { cancelComplaint } from '@/config/apis.js'
export default {
  name: 'ReportList',
  props: ['listdata'],
  methods: {
    clickImage (arr, index) {
      const images = arr.map(item => item.url)
      ImagePreview({
        images: images,
        showIndex: true,
        loop: false,
        startPosition: index
      })
    },
    revoke (id) {
      console.log(id)
      const that = this
      Dialog.confirm({
        messageAlign: 'left',
        message: '您确认要撤销此条举报信息吗？'
      })
        .then(() => {
          const data = { id }
          cancelComplaint(data).then(res => {
            if (res.code === 1) {
              Toast.success('操作成功')
              that.$emit('updateList')
            } else {
              Toast.fail(res.msg)
            }
          }).catch(e => {
            Toast.fail('服务器繁忙,稍后再试')
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.notice-index {
  margin: 0.2rem;
  background-color: #ffffff;
  padding: 0.2rem;
  border-radius: 10px;
  position: relative;
  .item {
    line-height: 24px;
    font-size: 12px;
    .title {
      font-size: 13px;
      color: #000000;
      font-weight: 500;
    }
    .content {
      color: #333333;
    }
  }
  .item-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .item;
  }
  .images-title {
    font-size: 13px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 0.1rem;
    line-height: 20px;
  }
  .images-box {
    display: flex;
    align-items: center;
    .image-item {
      margin-right: 5px;
    }
  }
  .status {
    z-index: 10;
    position: absolute;
    transform: rotate(-20deg);
    display: inline-block;
    bottom: 15%;
    right: 8%;
    border: 2px solid red;
    opacity: 0.8;
    color: red;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
  }
  .progress {
    z-index: 10;
    position: absolute;
    transform: rotate(-20deg);
    display: inline-block;
    top: 40%;
    left: 70%;
    border: 1px solid rgb(228, 228, 228);
    opacity: 0.8;
    color: rgb(145, 145, 145);
    text-align: center;
    width: 80px;
    height: 26px;
    // border-radius: 50%;
    line-height: 26px;
  }
}
</style>
